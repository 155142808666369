<template>
  <div class="contents-area page-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1>{{ listPage.title || translations("PageLoading") }}</h1>
          <Breadcrumbs
            :curItem="{ name: listPage.title || translations('PageLoading') }"
          />
        </div>
        <PageContents />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageContents from "../components/PageContents.vue";

export default {
  name: "Page",
  components: { PageContents },
  computed: {
    ...mapGetters(["listPage", "translations"]),
  },
};
</script>