<template>
  <h1>{{ translations("PageNotFound") }}</h1>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NotFound",
  computed: {
    ...mapGetters(["translations"]),
  },
};
</script>