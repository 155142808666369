<template>
  <div class="page-description-contents">
    <div v-if="emptyPage" class="loading-data">
      <Loader />
    </div>
    <div v-else-if="listPage.title" class="page-area">
      <h3 v-if="listPage.subtitle">{{ listPage.subtitle }}</h3>
      <img v-if="listPage.image" :src="listPage.image" :alt="listPage.title" />
      <RichTextContents containerClass="page-contents" :text="listPage.text" />
    </div>
    <NotFound v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/app/Loader";
import NotFound from "@/components/app/NotFound";
import RichTextContents from "@/components/RichTextContents";

export default {
  name: "PageContents",
  metaInfo() {
    return {
      title: this.listPage.title,
    };
  },
  components: {
    Loader,
    NotFound,
    RichTextContents
  },
  data: () => {
    return {
      emptyPage: false,
    };
  },
  computed: {
    ...mapGetters(["listPage"]),
  },
  methods: {
    ...mapActions(["fetchPage"]),
  },
  async created() {
    //console.log('page created: ', this.$route.path)
    this.emptyPage = true;
    await this.fetchPage({
      link: this.$route.path,
      lang: this.$cookies.get("user_language"),
    });
    this.emptyPage = false;
  },
  mounted() {
    this.$root.$on("app-language", async function () {
      console.log(this.$route.path);
      this.$store.dispatch("fetchPage", {
        link: this.$route.path,
        lang: this.$cookies.get("user_language"),
      });
    });
  },
  watch: {
    async $route() {
      this.emptyPage = true;
      await this.fetchPage({
        link: this.$route.path,
        lang: this.$cookies.get("user_language"),
      });
      this.emptyPage = false;
    },
  },
  beforeDestroy() {
    this.$root.$off("app-language");
  },
};
</script>
